import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Slider from 'react-slick';
import { Container } from 'reactstrap';

import Menu from '../Menus/Menu';
import MobileMenu from '../Menus/MobileMenu';
import SubMenu from '../Menus/SubMenu';
import Search from '../Search';

import { toggleModal } from '../../containers/Menus/actions';
import { getSearch, toggleSearch } from '../../containers/Search/actions';
import './styles.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stikyClass: false,
      searchOpen: false,
      restart: false,
      headerClass: props.isHome ? 'header' : 'header inner',
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.status === 'set' &&
      this.props.current !== prevProps.current &&
      this.props.status !== prevProps.status
    ) {
      this.setState({
        headerClass: prevProps.isHome ? 'header' : 'header inner',
        restart: true,
      });
    } else if (
      this.props.status === 'set' &&
      this.props.current !== prevProps.current &&
      this.state.restart
    ) {
      this.setState({
        restart: false,
      });
    }
  }

  componentDidMount() {
    if (window) window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    if (window) window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    const currentHeight =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (currentHeight > 50 && !this.state.handleScroll) {
      this.setState({ handleScroll: true });
    } else if (currentHeight < 50 && this.state.handleScroll) {
      this.setState({ handleScroll: false });
    }
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  toggleModal(url, id) {
    this.props.toggleModal(url, id);
  }

  render() {
    const { head, head_options } = this.props.content;
    let fullscreen = false;
    let thumbnails = false;

    if (head_options) {
      fullscreen = head_options.fullscreen;
      thumbnails = head_options.thumbnails;
    }

    const settings = {
      dots: thumbnails,
      infinite: true,
      pauseOnHover: false,
      arrows: false,
      fade: true,
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      customPaging: i => {
        return (
          <a
            style={{
              backgroundImage: `url('${head[i].slide}')`,
            }}
            className={'pager'}
          />
        );
      },
    };

    return (
      <div
        className={`${fullscreen ? 'fullscreen' : ''} ${
          this.props.isHome ? 'header' : 'header inner'
        }`}
      >
        <div className={'menu-container'}>
          <div
            className={`menus clearfix ${
              this.state.handleScroll ? 'fixed' : ''
            }`}
          >
            <Container>
              <MobileMenu
                isHome={this.props.isHome}
                menu={[
                  ...(this.props.topmenu || []),
                  ...(this.props.shopmenu || []),
                ]}
                location={this.props.current}
                options={this.props.options.content}
                submenu={this.props.submenu}
                lang={this.props.lang}
                toggleModal={this.toggleModal}
              />
              <SubMenu
                content={this.props.submenu}
                location={this.props.current}
                lang={this.props.lang}
                toggleModal={this.toggleModal}
              />
              <Menu
                isHome={this.props.isHome}
                content={this.props.topmenu}
                rightmenu={this.props.shopmenu}
                options={this.props.options.content}
                location={this.props.current}
                lang={this.props.lang}
                toggleModal={this.toggleModal}
                toggleSearch={this.props.toggleSearch}
              />
            </Container>
          </div>
        </div>
        <div className={'logo-background'} />

        {head && !this.state.restart && (
          <Slider ref={c => (this.slider = c)} {...settings}>
            {head.map((slide, index) => (
              <div key={index} className={'header-wrapper'}>
                <div
                  className={'header-slide'}
                  style={{
                    backgroundImage: `url('${slide.slide}')`,
                  }}
                >
                  <div className={'read-safe'} />
                  <div className={'read-shadow'} />
                  <div
                    className={'abolute-elemtns animated fadeIn'}
                    style={{
                      animationDelay: `1s`,
                      animationDuration: `2s`,
                    }}
                  >
                    <Container>
                      <div className={'header-text-wrapper'}>
                        <div>
                          {slide.sub_title && (
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: slide.sub_title,
                              }}
                            />
                          )}
                          {slide.title && (
                            <h2
                              dangerouslySetInnerHTML={{ __html: slide.title }}
                            />
                          )}

                          {slide.text && (
                            <div
                              className={'content'}
                              dangerouslySetInnerHTML={{ __html: slide.text }}
                            />
                          )}
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
        {head.length > 1 && (
          <div className="arrows" style={{ textAlign: 'center' }}>
            <a className="prev" onClick={this.previous} />
            <a className="next" onClick={this.next} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  topmenu: state.menus.topmenu,
  submenu: state.menus.submenu,
  options: state.options.options,
  shopmenu: state.menus.shopmenu,
  status: state.content.status,
  content: state.content.currentContent && state.content.currentContent.acf,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleModal, toggleSearch }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

Header.propTypes = {
  content: PropTypes.object,
  topmenu: PropTypes.array,
  submenu: PropTypes.array,
};

Header.defaultProps = {
  content: {
    slides: [],
  },
  topmenu: [],
  submenu: [],
};
