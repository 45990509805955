export function excerptFrom(size, sting) {
  const maxLength = size; // maximum number of characters to extract

  //trim the string to the maximum length
  const trimmedString = sting.substr(0, maxLength);

  //re-trim if we are in the middle of a word
  return trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );
}
