import React from 'react';
import { Link } from 'react-router-dom';
import Page from '../../components/page';
import './styles.css';
export default () => (
  <Page
    id="not-found"
    title="Not Found"
    description="This is embarrassing."
    noCrawl
  >
    <div className={'notfound'}>
      <h1>404</h1>
      <h4> page not found</h4>
      <p>
        go back to the <Link to={'/'}>homepage</Link>
      </p>
    </div>
  </Page>
);
