import React from 'react';
// import { Map, TileLayer, CircleMarker, Popup } from 'react-leaflet';
// import 'leaflet.tilelayer.colorfilter';
import { Container, Row, Col } from 'reactstrap';
import 'leaflet/dist/leaflet.css';
import './styles.scss';

function LayoutMap(props) {
  const content = props.content;
  const state = {
    lat: 38.714951,
    lng: -9.07744,
    zoom: props.content.zoom || 2,
  };
  if (content.locations.lenght < 1) return null;

  const position = [
    content.locations[0] ? content.locations[0].geolocation.lat : 0,
    content.locations[0] ? content.locations[0].geolocation.lng : 0,
  ];

  if (process.title == 'browser') {
    // SSR loading problem
    var { Map, TileLayer, CircleMarker, Popup } = require('react-leaflet');
    let oldFilter = ['grayscale:100%', 'invert:100%'];

    return (
      <Container
        id={props.id}
        className={`${props.first ? 'first' : ''} map-content`}
      >
        <Map center={position} zoom={state.zoom} scrollWheelZoom={false}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            filter={oldFilter}
          />

          {content.locations[0] &&
            content.locations.map((location, index) => (
              <CircleMarker
                key={index}
                center={[location.geolocation.lat, location.geolocation.lng]}
                radius={20}
                border={5}
              >
                <Popup>
                  <h6>{location.title}</h6>
                  <p dangerouslySetInnerHTML={{ __html: location.content }} />
                </Popup>
              </CircleMarker>
            ))}
        </Map>
      </Container>
    );
  } else {
    return null;
  }
}

export default LayoutMap;
