import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import ReadMore from '../ReadMore';
import './styles.css';

export default class LayoutIntro extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const content = this.props.content;
    return (
      <div id={this.props.id} className={'layout-intro'}>
        <div className={'half-background'}>
          <h5 className={'title mobile'}>{content.title}</h5>
          <div
            className={'content'}
            dangerouslySetInnerHTML={{
              __html: content.content,
            }}
          />
        </div>
        <Container className={'background-layout'}>
          <Row>
            <Col
              xs="12"
              sm="12"
              md={'6'}
              className={'title-side align-items-center align-self-center'}
            >
              <h5 className={'title'}>{content.title}</h5>
              <div className={`color-line-right`} />
            </Col>
            <Col
              xs="12"
              sm="12"
              md={'6'}
              className={'text align-items-center align-self-center'}
            />
          </Row>
        </Container>
      </div>
    );
  }
}
