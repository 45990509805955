import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './styles.scss';
import Button from '../Button';

function LayoutButton(props) {
  const content = props.content;
  if (!content) return null;
  return (
    <Container className={`content-button`}>
      {content.button_text && (
        <Button to={content.link}>{content.button_text}</Button>
      )}
    </Container>
  );
}

export default LayoutButton;
