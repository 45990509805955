import React, { Component } from 'react';
import Stepper from 'react-stepper-horizontal';
import Title from '../Title';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'reactstrap';
import './styles.css';

export default class LayoutTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
    };
  }

  render() {
    // const { steps, currentStep } = this.state;
    const { content } = this.props;
    return (
      <div className={'history'}>
        <Container>
          <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
            <Title>{content.title}</Title>

            <div className={'history-logo'}>
              <img src={content.image.url} alt={content.image.alt} />
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInUp"
            delay={100}
            animateOut="fadeOut"
          >
            <div className={'stepper'}>
              <Stepper
                completeColor={'#8ECA80'}
                defaultColor={'#8ECA80'}
                activeColor={'#8ECA80'}
                fontWeight={'400'}
                activeTitleColor={'#8ECA80'}
                completeTitleColor={'#8ECA80'}
                defaultBarColor={'#8ECA80'}
                completeBarColor={'#8ECA80'}
                circleFontSize={0}
                size={12}
                steps={content.events}
                activeStep={content.events.length - 1}
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInUp"
            delay={200}
            animateOut="fadeOut"
          >
            <div className={'content'}>
              <p dangerouslySetInnerHTML={{ __html: content.content }} />
            </div>
          </ScrollAnimation>
        </Container>
      </div>
    );
  }
}
