import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.scss';

function LayoutContent(props) {
  const content = props.content;
  return (
    <div className={`content-column ${props.first && 'first'}`}>
      <Container>
        <Row>
          <Col xs="12" md="6" lg="5">
            {
              // <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
              <div className={'highlight'}>
                <h5 dangerouslySetInnerHTML={{ __html: content.title }} />
                <div dangerouslySetInnerHTML={{ __html: content.highlight }} />
                <span className={'blocks-line'} />
              </div>
              // </ScrollAnimation>
            }
          </Col>
          <Col xs="12" md="6" lg="7">
            <ScrollAnimation
              animateIn="fadeIn"
              delay={100}
              animateOut="fadeOut"
            >
              <div
                className={'content'}
                dangerouslySetInnerHTML={{ __html: content.content }}
              />
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LayoutContent;
