import React from 'react';
import LayoutAlternateContent from '../LayoutAlternateContent';
import { Container } from 'reactstrap';
import Title from '../Title';
import './styles.scss';
import config from '../../../config';
import Button from '../Button';

function LayoutListContentAlternate(props) {
  const content = props.content;

  if (!content || !content.content) return <div />;
  return (
    <div
      className={'content-list-alternate-wrapper'}
      id={content.title.toLowerCase().replace(/ /g, '_')}
      style={{ backgroundColor: content.background_colour }}
    >
      <Container>
        <Title title={content.title} subtitle={content.sub_title} />

        {content.content.map((cont, i) => {
          if (!cont.acf.excerpt) return false;

          return (
            <div key={i} className={'content-list-alternate'}>
              <LayoutAlternateContent
                odd={i % 2}
                first={i === 0}
                content={{
                  title: cont.title.rendered,
                  content: cont.acf.excerpt.summary,
                  image: cont.acf.excerpt.image,
                  link: cont.link.replace(config.websiteURL, ''),
                  button_text: content.button_text,
                }}
              />
            </div>
          );
        })}
        {content.button_text && (
          <div className={'alternate-actions'}>
            <Button to={`/${content.content_type}`}>
              {content.button_text}
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
}

export default LayoutListContentAlternate;
