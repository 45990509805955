import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.css';

function LayoutContentSidebySide(props) {
  const content = props.content;
  return (
    <div
      className={`content-sidebyside ${props.first && 'first'}`}
      style={{
        backgroundImage: `url('${
          content.right.background ? content.right.background.sizes.large : ''
        }')`,
      }}
    >
      <Container>
        <Row>
          <Col xs="12" md="8" lg="8" className={' clearfix'}>
            <div className={'left'}>
              <h5 dangerouslySetInnerHTML={{ __html: content.left.title }} />
              <div dangerouslySetInnerHTML={{ __html: content.left.content }} />
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className={'right'}
        style={{
          backgroundImage: `url('${
            content.right.background ? content.right.background.sizes.large : ''
          }')`,
        }}
      >
        <div>
          <h5 dangerouslySetInnerHTML={{ __html: content.right.title }} />
          <div className={'color-line'} />
          <div
            className={'content'}
            dangerouslySetInnerHTML={{ __html: content.right.content }}
          />
        </div>
      </div>
    </div>
  );
}

export default LayoutContentSidebySide;
