import * as constants from './constants';

let initialState = {
  currentContent: [],
  status: '',
  categories: {},
  categoryContent: {},
};

const isLocalStorage = typeof localStorage !== 'undefined';
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.content) {
  initialState = JSON.parse(localStorage.content);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_MORE_CONTENT_REQUEST:
      return {
        ...state,
        status: 'getting',
      };
    case constants.GET_MORE_CONTENT:
      // now lets set the content in the content
      state.currentContent.acf.content = state.currentContent.acf.content.map(
        (cont2, i) => {
          if (
            (cont2.acf_fc_layout == 'content_list_alternate' ||
              cont2.acf_fc_layout == 'content_list_highlight' ||
              cont2.acf_fc_layout == 'content_list_map' ||
              cont2.acf_fc_layout == 'content_list_gallery' ||
              cont2.acf_fc_layout == 'content_list_grid_slideshow' ||
              cont2.acf_fc_layout == 'content_list_horizontal_image' ||
              cont2.acf_fc_layout == 'content_list_text_slider' ||
              cont2.acf_fc_layout == 'content_list_grid') &&
            cont2.fields.content_type === action.content.type
          ) {
            return {
              ...cont2,
              fields: {
                ...cont2.fields,
                page: action.content.page,
                content: [...cont2.fields.content, ...action.content.content],
              },
            };
          }

          return cont2;
        }
      );
      newState = {
        ...state,
        status: 'updated',
      };
      if (isLocalStorage) localStorage.content = JSON.stringify(newState);
      return newState;
    case constants.SET_CURRENT_CONTENT_REQUEST:
      return {
        ...state,
        status: 'getting',
      };
    case constants.SET_CURRENT_CONTENT:
      newState = {
        ...state,
        status: 'set',
        currentContent: action.content[0],
      };
      if (isLocalStorage) localStorage.content = JSON.stringify(newState);
      return newState;
    case constants.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        status: 'getting',
      };
    case constants.GET_CATEGORIES:
      newState = {
        ...state,
        status: 'set',
        categories: {
          ...state.categories,
          [action.categories.name]: action.categories.data,
        },
      };
      if (isLocalStorage) localStorage.content = JSON.stringify(newState);
      return newState;
    case constants.GET_CATEGORY_CONTENT:
      return {
        ...state,
        status: 'get',
      };
    case constants.SET_CATEGORY_CONTENT:
      return {
        ...state,
        status: 'set',
        categoryContent: {
          ...state.categoryContent,
          [action.payload.id]: action.payload.content.data,
        },
      };

    default:
      return state;
  }
};
