import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import ReadMore from '../ReadMore';
import './styles.css';

function LayoutContentSocial(props) {
  const url = '';
  return (
    <div
      id={props.id}
      className={
        props.content.background_image
          ? 'text-block-background-sc'
          : 'horizontal-text-sc'
      }
      style={{
        backgroundImage: `url('${props.content.background_image &&
          props.content.background_image.sizes.large}')`,
        backgroundColor: `${props.content.background_color || '#8eca80'}`,
      }}
    >
      <Container>
        <Row>
          <Col xs="12" sm="12" md={'6'} className={'title'}>
            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              <h5
                style={{
                  color: `${props.content.text_color || '#0D3C47'}`,
                }}
              >
                {props.content.title}
              </h5>
              <div
                style={{
                  color: `${props.content.text_color || '#69AEBE'}`,
                }}
                dangerouslySetInnerHTML={{ __html: props.content.text }}
              />
            </ScrollAnimation>
          </Col>

          <Col xs="12" sm="12" md={'6'} className={'text'}>
            <ScrollAnimation
              animateIn="fadeIn"
              delay={150}
              animateOut="fadeOut"
            >
              {props.content.facebook &&
                props.content.facebook !== '' && (
                  <a className={'social-button'} href={props.content.facebook}>
                    <i className="icons icon-social-facebook" /> | Facebook
                  </a>
                )}
              {props.content.twitter &&
                props.content.twitter !== '' && (
                  <a className={'social-button'} href={props.content.twitter}>
                    <i className="icons icon-social-twitter" /> | Twitter
                  </a>
                )}
              {props.content.youtube &&
                props.content.youtube !== '' && (
                  <a className={'social-button'} href={props.content.youtube}>
                    <i className="icons icon-social-youtube" /> | Youtube
                  </a>
                )}
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LayoutContentSocial;
