import React, { Component } from 'react';
import { Container, Row, Col, Form, Input } from 'reactstrap';
import Title from '../Title';
import Button from '../Button';
import axios from 'axios';
import './styles.scss';
import config from '../../../config';

import 'leaflet/dist/leaflet.css';
import './styles.css';

export default class LayoutContactMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      name: '',
      email: '',
      message: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.setMessage = this.setMessage.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ status: this.validadeState() ? 'ok' : '' });
  }

  validadeState() {
    const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.name.length < 4) return false;
    if (!emailRE.test(this.state.email)) return false;
    if (this.state.message.split(' ').length < 5) return false;

    return true;
  }

  setMessage() {
    this.setState({ status: 'loading' });
    // no content saved lets fecth it fromjson
    var params = new URLSearchParams();
    params.append('email', this.state.email);
    params.append('name', this.state.name);
    params.append('message', this.state.message);
    axios
      .post(`${config.apiURL}wp-json/mail/v1/contact`, params)
      .then(result => {
        this.setState({ status: 'success' });
      })
      .catch(error => {
        this.setState({ status: error });
      });
  }

  renderForm() {
    const {
      title,
      form,
      content,
      error,
      button_text,
      email,
      message,
      name,
    } = this.props.content;

    return (
      <div>
        {this.state.status !== 'error' ? (
          <Title>{title}</Title>
        ) : (
          <Title>
            <strong>{error}</strong>
          </Title>
        )}

        <Form>
          <Row>
            <Col>
              <Input
                type={'text'}
                name={'name'}
                onChange={this.handleChange}
                value={this.state.name}
                placeholder={name}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                value={this.state.email}
                placeholder={email}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                type="textarea"
                name="message"
                onChange={this.handleChange}
                value={this.state.message}
                placeholder={message}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                disabled={
                  this.state.status !== 'ok' && this.state.status !== 'error'
                }
                onClick={() => this.setMessage()}
              >
                {button_text} <i className={'icons icon-paper-plane'} />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
  renderThankyou() {
    return (
      <Title>
        <strong>{this.props.content.success}</strong>
      </Title>
    );
  }
  renderLoading() {
    return (
      <Title>
        <strong>{this.props.content.sending}</strong>
      </Title>
    );
  }

  renderMap() {
    const content = this.props.content;
    const state = {
      lat: content.map.lat,
      lng: content.map.lng,
      zoom: 14,
    };
    const position = [content.map.lat, content.map.lng];
    if (process.title == 'browser') {
      // SSR loading problem
      var { Map, TileLayer, CircleMarker, Popup } = require('react-leaflet');

      return (
        <div id={this.props.id} className={'map'}>
          <Map center={position} zoom={state.zoom}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <CircleMarker
              center={[content.map.lat, content.map.lng]}
              radius={20}
              border={5}
            >
              <Popup>
                <h6>{content.title}</h6>
                <p dangerouslySetInnerHTML={{ __html: content.content }} />
              </Popup>
            </CircleMarker>
          </Map>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    let body = {};
    switch (this.state.status) {
      case 'success':
        body = this.renderThankyou();
        break;
      case 'loading':
        body = this.renderLoading();
        break;
      default:
        body = this.renderForm();
        break;
    }

    return (
      <div className={'contacts'}>
        <Row>
          <Col xs="12" md="6">
            <div className={'map-container'}>
              {this.renderMap()}
              {/* <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyBMsImhpIrlyFwko7qiJ-nl6bAOtAwwCfc',
                }} // set if you need stats etc ...
                center={[59.8829892, 14.968567]}
                zoom={17}
              >
                <Marker
                  lat={59.8840842}
                  lng={14.9675866}
                  text={this.props.content.content}
                />
              </GoogleMapReact> */}
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className={'contactus-container'}>{body}</div>
          </Col>
        </Row>
      </div>
    );
  }
}
