import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

function SubMenu(props) {
  return (
    <div className={'submenu clearfix'}>
      <ul>
        {props.content.map((col, index) => (
          <li
            key={index}
            // className={'fadeIn animated'}
            style={{
              animationDelay: `${index + 0.2}s`,
              animationDuration: `2s`,
            }}
          >
            <Link
              className={props.location === col.link ? 'active' : 'notactive'}
              to={col.link}
            >
              {col.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SubMenu;
