import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import createStore from './store';
import NotFound from './app/routes/not-found';
import config from './config';

import './index.scss';
import App from './app/app';
import * as serviceWorker from './serviceWorker';

const { store, history } = createStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          path={`/:lang(${config.languages.join('|')})?`}
          component={App}
        />
        <Route component={NotFound} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
