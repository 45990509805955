import axios from 'axios';
import offlineAdapter from './offlineAxios';

const offline = offlineAdapter({
  name: 'axios-offline',
  adapter: axios.defaults.adapter,
});

export const http = axios.create({
  adapter: offline,
  withCredentials: true,
});
