import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../../config';
import './styles.scss';

function Button(props) {
  return (
    <Link
      className={`section-button ${props.className}`}
      to={props.to && props.to.replace(config.websiteURL, '')}
    >
      {props.children}
    </Link>
  );
}

export default Button;
