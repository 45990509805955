import React, { Component } from 'react';
import { Container, Row, Col, Form, Input } from 'reactstrap';
import Title from '../Title';
import Button from '../Button';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import './styles.scss';
import config from '../../../config';

export default class LayoutContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      name: '',
      email: '',
      message: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.setMessage = this.setMessage.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ status: this.validadeState() ? 'ok' : '' });
  }

  validadeState() {
    const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.name.length < 4) return false;
    if (!emailRE.test(this.state.email)) return false;
    if (this.state.message.split(' ').length < 5) return false;

    return true;
  }

  setMessage() {
    const { send_to } = this.props;
    this.setState({ status: 'loading' });
    // no content saved lets fecth it fromjson
    var params = new URLSearchParams();
    params.append('email', this.state.email);
    params.append('name', this.state.name);
    params.append('message', this.state.message);
    axios
      .post(`${config.apiURL}/wp-json/mail/v1/contact`, params)
      .then(result => {
        this.setState({ status: 'success' });
      })
      .catch(error => {
        console.log(error);
        this.setState({ status: 'error' });
      });
  }

  renderForm() {
    if (!this.props.content) return null;

    const {
      title,
      button_text,
      email_label,
      message_label,
      name_label,
      side_text,
    } = this.props.content;

    return (
      <div>
        <Form>
          {this.state.status !== 'error' ? (
            <Title title={title}></Title>
          ) : (
            <Title title={this.props.content.error_message}></Title>
          )}
          <Row>
            <Col
              xs="12"
              md={{ size: 6, order: 1, offset: 3 }}
              className={'content align-items-center align-self-center'}
            >
              <Input
                type={'text'}
                name={'name'}
                onChange={this.handleChange}
                value={this.state.name}
                placeholder={name_label}
              />

              <Input
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                value={this.state.email}
                placeholder={email_label}
              />

              <Input
                type="textarea"
                name="message"
                onChange={this.handleChange}
                value={this.state.message}
                placeholder={message_label}
              />

              <a
                className={`section-button ${
                  this.state.status !== 'ok' && this.state.status !== 'error'
                    ? 'disabled'
                    : 'enabled'
                }`}
                onClick={() => this.setMessage()}
              >
                {button_text}
              </a>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
  renderThankyou() {
    return <Title title={this.props.content.success_message}></Title>;
  }
  renderLoading() {
    return <Title title={this.props.content.progress_message}></Title>;
  }
  render() {
    let body = {};
    switch (this.state.status) {
      case 'success':
        body = this.renderThankyou();
        break;
      case 'loading':
        body = this.renderLoading();
        break;
      default:
        body = this.renderForm();
        break;
    }

    const { background_image, background_color } = this.props.content;
    return (
      <div
        className={'contact-form'}
        style={{
          backgroundColor: `${background_color}`,
          backgroundImage: `url('${background_image &&
            background_image.sizes.large}')`,
        }}
      >
        <Container>
          <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
            <div className={'contactus-container'}>{body}</div>
          </ScrollAnimation>
        </Container>
      </div>
    );
  }
}
