import React from 'react';
import Slider from 'react-slick';
import { Container, Row, Col } from 'reactstrap';
import Title from '../Title';
import LayoutGridElement from '../LayoutGridElement';
import LayoutGridElementHover from '../LayoutGridElementHover';
import config from '../../../config';

import ScrollAnimation from 'react-animate-on-scroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.css';

function LayoutListContentTextSlideshow(props) {
  const content = props.content.content;
  if (!content) return null;
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    fade: true,
    speed: 500,
    autoplay: content.length > 1,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      className={`grid-text-slideshow ${props.first && 'first'}`}
      id={props.id}
    >
      <Slider {...settings}>
        {content &&
          content.map((item, index) => {
            if (!item.acf.excerpt) return null;
            const elementData = {
              type: props.content.content_type,
              image: item.acf.excerpt.image,
              link: item.link.replace(config.websiteURL, ''),
              title: item.title.rendered,
              content: item.acf.excerpt.summary,
              button_text: props.content.button_text,
            };

            return (
              item.acf.excerpt && (
                <div key={index}>
                  <div className={'summary'}>{item.acf.excerpt.summary}</div>

                  <div className={'button'}>
                    <a href={item.link.replace(config.websiteURL, '')}>
                      {props.content.button_text}
                    </a>
                  </div>
                </div>
              )
            );
          })}
      </Slider>
    </div>
  );
}

export default LayoutListContentTextSlideshow;
