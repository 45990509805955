import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Page from '../../components/page';
import DynamicContent from '../../components/DynamicContent';

import {
  getContentPage,
  getMoreContentPage,
  removeCurrentContent,
} from '../../containers/Content/actions';
import { setLanguage } from '../../containers/Language/actions';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.match.params.content,
    };
    this.getContentPage = this.getContentPage.bind(this);

    const { lang, content, category, type } = props.match.params;
    const loadContent = content === 'index.html' ? undefined : content;
    const loadLanguage = lang || props.language;
    const loadType = type;
    const loadCategory = category;
    props.getContentPage(loadContent, loadLanguage, loadType, loadCategory);
  }

  getContentPage(event) {
    const {
      content,
      lang,
      category,
      categories,
      type,
    } = this.props.match.params;
    if (event.data.adminreload) {
      this.props.getContentPage(content, lang, type, category, categories);
    }
  }

  componentDidMount() {
    // stuff for admin edit
    const self = this;
    window.addEventListener('message', this.getContentPage);
  }

  componentWillUnmount() {
    this.props.removeCurrentContent();
    // stuff for admin edit
    const self = this;
    if (this.props.user) {
      window.removeEventListener('message', this.getContentPage);
    }
  }
  componentDidUpdate() {
    if (!this.state.animateTransition)
      this.setState({
        animateTransition: true,
      });
  }

  shouldComponentUpdate(nextProps) {
    const {
      content,
      lang,
      category,
      categories,
      type,
    } = nextProps.match.params;
    if (
      this.props.match.params.content !== content ||
      this.props.match.params.category !== category
    ) {
      // this.props.removeCurrentContent();
      this.props.getContentPage(content, lang, type, category, categories);
      this.setState({
        ishome: !content,
        content: content,
        animateTransition: false,
      });
      window.scrollTo(0, 0);
    }
    return true;
  }

  loadMoreContentPage(type, page, total) {
    this.props.getMoreContentPage(
      page,
      this.props.language,
      type,
      total,
      this.props.match.params.category,
      this.props.categories
    );
  }

  render() {
    const { content, status } = this.props;
    if (!content || content.length === 0) return <div>Loading ...</div>;

    const yoast_meta = content.yoast_meta;

    return (
      <Page
        id="page_content"
        title={yoast_meta && yoast_meta.yoast_wpseo_title}
        description={yoast_meta && yoast_meta.yoast_wpseo_metadesc}
        link={yoast_meta && yoast_meta.yoast_wpseo_canonical}
      >
        <DynamicContent
          content={content}
          language={this.props.language}
          category={this.props.match.params.category}
          status={status}
          loadMoreContentPage={this.loadMoreContentPage}
        />
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  content: state.content.currentContent,
  categories: state.content.categories,
  status: state.content.status,
  user: state.admin.user,
  language: state.language,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getContentPage, setLanguage, removeCurrentContent, getMoreContentPage },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);

Content.propTypes = {
  content: PropTypes.any,
};

Content.defaultProps = {
  content: {
    yoast_meta: {},
    acf: {
      content: [],
    },
  },
};
