import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import ReadMore from '../ReadMore';

import './styles.css';

function TexBlockBackground(props) {
  function calculatePrecentageCircle(number) {
    const circumference = 565.4866;
    return -circumference * (number / 100) + circumference;
  }

  return (
    <div id={props.id} className={'horizontal-text-block-graph'}>
      <Container>
        <Row>
          <Col
            xs="12"
            md="12"
            lg="12"
            className={'content align-items-center align-self-center'}
          >
            <Col
              xs="12"
              md="12"
              className={'align-items-center align-self-center'}
            >
              <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
                {props.content.sub_title && (
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: props.content.sub_title,
                    }}
                  />
                )}
                <h5 dangerouslySetInnerHTML={{ __html: props.content.title }} />
                <p
                  dangerouslySetInnerHTML={{ __html: props.content.content }}
                />
                {props.content.link && (
                  <ReadMore link={props.content.link}>
                    {props.content.button_text}
                  </ReadMore>
                )}
              </ScrollAnimation>
            </Col>
            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              {props.content.numbers &&
                props.content.numbers.map(group => (
                  <div className="item html">
                    <div className={'lable-content'}>
                      <h2>{group.number.percentage}</h2>
                      <p>{group.number.label}</p>
                    </div>
                    <svg
                      width="200"
                      height="200"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <title>{group.number.label}</title>
                        <circle
                          className="circle_animation_bottom"
                          r="90"
                          cy="100"
                          cx="100"
                          strokeWidth="3"
                          stroke="#64abbb"
                          fill="none"
                        />
                        <circle
                          className="circle_animation"
                          style={{
                            strokeDashoffset: calculatePrecentageCircle(
                              group.number.percentage
                            ),
                          }}
                          r="90"
                          cy="100"
                          cx="100"
                          strokeWidth="4"
                          stroke="#41566D"
                          fill="none"
                        />
                      </g>
                    </svg>
                  </div>
                ))}
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TexBlockBackground;
