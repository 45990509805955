import React from 'react';
import Slider from 'react-slick';
import { Container, Row, Col } from 'reactstrap';
import Title from '../Title';
import config from '../../../config';
import { Link } from 'react-router-dom';
import ReadMore from '../ReadMore';

import ScrollAnimation from 'react-animate-on-scroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.css';

function LayoutGridElement(props) {
  const {
    image,
    link,
    title,
    content,
    button_text,
    dimensions,
    type,
    acf,
    price,
    currency_symbol,
  } = props;

  return (
    <div className={'grid-slide-element'}>
      <Link to={link} className={'image'} />
      <div className={'content'}>
        <h6>{title}</h6>
        <p>{content}</p>

        {button_text && <ReadMore link={link}>{button_text}</ReadMore>}
      </div>
    </div>
  );
}

function LayoutListContentGridSlideshow(props) {
  const content = props.content.content;
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: content.length > 3,
    autoplaySpeed: 5000,
    slidesToShow: content.length > 3 ? 4 : content.length,
    slidesToScroll: content.length > 3 ? 4 : content.length,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          autoplay: content.length > 2 ? 2 : content.length,
          slidesToScroll: content.length > 2 ? 2 : content.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplay: content.length > 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={'grid-slideshow'} id={props.id}>
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <Title>{props.content.title}</Title>

        <Slider {...settings}>
          {content &&
            content.map((item, index) => {
              if (!item.acf.excerpt) return null;

              const elementData = {
                type: props.content.content_type,
                image: item.acf.excerpt.image,
                link: item.link.replace(config.websiteURL, ''),
                title: item.title.rendered,
                content: item.acf.excerpt.summary,
                button_text: props.content.button_text,
              };

              return (
                item.acf.excerpt && (
                  <div key={index} className={'grid-slides'}>
                    <LayoutGridElement {...elementData} />
                  </div>
                )
              );
            })}
        </Slider>
      </ScrollAnimation>
    </div>
  );
}

export default LayoutListContentGridSlideshow;
