import React from 'react';
import Slider from 'react-slick';
import { Container, Row, Col } from 'reactstrap';
import Title from '../Title';
import ProductItem from '../ProductItem';
import ScrollAnimation from 'react-animate-on-scroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.css';

function LayoutContentSlider(props) {
  const content = props.content;
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: content.content.length > 3,
    autoplaySpeed: 5000,
    slidesToShow: content.content.length > 3 ? 3 : content.content.length,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          autoplay: content.content.length > 2 ? 2 : content.content.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplay: content.content.length > 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container
      id={props.id}
      className={`content-slider  ${props.first && 'first'}`}
    >
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        {content.title && <Title>{content.title}</Title>}
        <Slider {...settings}>
          {content.content.map((item, index) => {
            return (
              <div key={index}>
                <div
                  className={'item'}
                  style={{
                    backgroundImage: `url('${item.background_image &&
                      item.background_image.background &&
                      item.background_image.background.sizes &&
                      item.background_image.background.sizes.large}')`,
                  }}
                >
                  <div className={'arrange-item'}>
                    {item.icon &&
                      item.icon.sizes && (
                        <div className={'icon'}>
                          <img src={item.icon.sizes.medium} />
                        </div>
                      )}
                    <div className={'text'}>
                      <h4
                        dangerouslySetInnerHTML={{ __html: item.title }}
                        style={{
                          color: `${
                            item.background_image &&
                            item.background_image.text_color
                              ? item.background_image.text_color
                              : '#41566D'
                          }`,
                        }}
                      />
                      <p
                        dangerouslySetInnerHTML={{ __html: item.content }}
                        style={{
                          color: `${
                            item.background_image &&
                            item.background_image.text_color
                              ? item.background_image.text_color
                              : '#41566D'
                          }`,
                        }}
                      />
                      {item.button_text && (
                        <a className={'action'} href={item.link}>
                          {item.button_text}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </ScrollAnimation>
    </Container>
  );
}

export default LayoutContentSlider;
