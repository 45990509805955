import * as constants from './constants';

const initialState = {
  status: '',
  page: 0,
  displaySearch: false,
  data: [],
};

export default (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case constants.GET_SEARCH:
      return { ...state, status: 'getting' };
    case constants.TOGGLE_SEARCH:
      return { ...state, displaySearch: !state.displaySearch };
    case constants.SET_SEARCH:
      newState = {
        ...state,
        status: 'set',
        page: action.data.page,
        data:
          action.data.page === 1
            ? action.data.results || []
            : [...state.data, ...action.data.results],
      };

      return newState;

    default:
      return state;
  }
};
