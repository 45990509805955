import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-responsive-modal';
import { Container, Row, Col, Form, Input, Button } from 'reactstrap';
import { debounce } from 'lodash';
import ReadMore from '../ReadMore';
import { getSearch, toggleSearch } from '../../containers/Search/actions';
import config from '../../../config';
import './styles.css';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
    };
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.getSearch = debounce(this.props.getSearch, 1000);
  }

  setSearchTerm(e) {
    this.setState({ searchTerm: e.target.value });
    this.getSearch(e.target.value, this.props.lang, 1);
  }

  getMoreContent() {
    const currentPage = this.props.page || 1;
    // first check if we have more content
    const moreContent =
      this.props.results && this.props.results.length >= 10 * currentPage;
    // let check if we are already loading
    if (moreContent && this.props.status === 'set') {
      return (
        <div className={'loadmore-wrap'}>
          <a
            className={'loadmore'}
            onClick={() =>
              this.getSearch(
                this.state.searchTerm,
                this.props.lang,
                currentPage + 1
              )
            }
          >
            {'Load more'} <i className={'icons icon-arrow-down'} />
          </a>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.displaySearch}
          onClose={this.props.toggleSearch}
          closeOnOverlayClick={true}
          closeOnEsc={true}
          classNames={{ modal: 'search-modal', overlay: 'search-overlay' }}
        >
          <Container>
            <Input
              type={'text'}
              name={'name'}
              onChange={this.setSearchTerm}
              focus={1}
              value={this.state.searchTerm}
              placeholder={'- Please type your search'}
            />
          </Container>

          {this.props.results &&
            this.props.results.length > 0 && (
              <Container className={'content'}>
                <Row>
                  <Col>
                    {this.props.results.map((result, index) => {
                      const summary =
                        result.acf &&
                        result.acf.excerpt &&
                        result.acf.excerpt.summary
                          ? result.acf.excerpt.summary
                          : result.excerpt.rendered;
                      const iamgeURL =
                        result.acf &&
                        result.acf.excerpt &&
                        result.acf.excerpt.image &&
                        result.acf.excerpt.image.sizes.thumbnail;
                      return (
                        <li key={index}>
                          {iamgeURL && (
                            <div>
                              <img src={iamgeURL} />
                            </div>
                          )}
                          <div>
                            <h6>{result.title.rendered}</h6>
                            {summary}
                            <ReadMore
                              hook={() => this.props.toggleSearch()}
                              link={result.link}
                            >
                              View this page
                            </ReadMore>
                          </div>
                        </li>
                      );
                    })}
                    {this.getMoreContent()}
                  </Col>
                </Row>
              </Container>
            )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  displaySearch: state.search.displaySearch,
  status: state.search.status,
  results: state.search.data,
  page: state.search.page,
  lang: state.language,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getSearch, toggleSearch }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
