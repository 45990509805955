import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Slider from 'react-slick';
import Title from '../Title';
import Button from '../Button';
import ScrollAnimation from 'react-animate-on-scroll';
import config from '../../../config';
import { excerptFrom } from '../../utils/strings/excerpt';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';

export default class LayoutListGallery extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 800,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
    };
    const { content } = this.props.content;
    let slides = [];

    if (content) {
      slides = content.reduce((prev, curr) => {
        const mapCtype = {
          title: curr.title.rendered,
          image: curr.acf.excerpt.image,
          content: curr.acf.excerpt.summary,
          link: curr.link,
        };
        return [...prev, { ...mapCtype }];
      }, []);
    }

    return (
      <div className={'section-horizontal-gallery-wrap'}>
        <Container className={'section-horizontal-gallery'}>
          <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
            <Slider ref={c => (this.slider = c)} {...settings}>
              {slides.map(
                (slide, index) =>
                  slide.image && (
                    <div key={index}>
                      <div
                        className={'content-image'}
                        style={{
                          backgroundImage: `url('${slide.image.sizes.large}')`,
                        }}
                      />
                      <Container className={'content-placeholder'}>
                        <Title>{slide.title}</Title>
                        <p>{slide.content}</p>
                        {slide.link && (
                          <Button className={'action'}>
                            <Link
                              to={slide.link.replace(config.websiteURL, '')}
                            >
                              {this.props.button_text || 'ver mais'}
                            </Link>
                          </Button>
                        )}
                      </Container>
                    </div>
                  )
              )}
            </Slider>
          </ScrollAnimation>
        </Container>
        <div className={'squareBackground'}>
          <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
            {slides.length > 1 && (
              <div className="arrows" style={{ textAlign: 'center' }}>
                <a className="next" onClick={this.next} />
                <a className="prev" onClick={this.previous} />
              </div>
            )}
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}
