import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { scrollToItem } from '../../utils/scroll';
import './styles.scss';

class LayoutMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
    this.scrollToElement = this.scrollToElement.bind(this);
  }

  scrollToElement(link, index) {
    this.setState({ active: index });
    const item = document.getElementById(link.replace('#', ''));
    scrollToItem(item, 150);
  }

  renderLink(col, index) {
    if (col.link[0] === '/') {
      return (
        <Link to={col.link} dangerouslySetInnerHTML={{ __html: col.name }} />
      );
    } else {
      return (
        <span
          className={this.state.active == index ? 'active' : 'notactive'}
          onClick={() => this.scrollToElement(col.link, index)}
          dangerouslySetInnerHTML={{ __html: col.name }}
        />
      );
    }
  }
  render() {
    const { content } = this.props;
    return (
      <ul className={'wrap clearfix LayoutMenu'}>
        {content &&
          content.item &&
          content.item.map((item, index) => {
            return (
              <li
                key={index}
                className={'fadeIn animated'}
                style={{
                  animationDelay: `${index - 0.5 + 0.02}s`,
                  animationDuration: `2s`,
                }}
              >
                {this.renderLink(item, index)}
              </li>
            );
          })}
      </ul>
    );
  }
}

export default LayoutMenu;
