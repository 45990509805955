import config from '../../../config';
import * as constants from './constants';
import { dataFetch } from '../Fetch';

/**
 *  if we have a category defined we will need to request the id before anything else
 */
const getCategories = (type, lang) => {
  try {
    if (type === 'products') {
      return dataFetch(
        type,
        `products/categories?lang=${lang}&per_page=99&status=publish`
      );
    }
    // lets get category if we need
    return dataFetch(type, `${type}_types?lang=${lang}&status=publish`);
  } catch (error) {
    console.log(error);
  }
};

/*
/ this gets more content for list
*/

export const getMoreContentPage = (
  pageNumber = 1,
  lang = config.defaultLang,
  type = 'pages',
  number = config.listSize,
  category = null,
  categories = null
) => async dispatch => {
  //http://localhost:8080/wp-json/wp/v2/landingpages?lang=de
  dispatch({
    type: constants.GET_MORE_CONTENT_REQUEST,
  });

  const nextPage = pageNumber + 1;

  let categoryFound = null;

  // if we have a category selected we need to fech only posts from that category
  if (category && !categories) {
    const fetchCategories = await getCategories(type, lang);
    categoryFound = fetchCategories.data.find(cat => cat.slug === category);
    // lets set the categories
    dispatch({
      type: constants.GET_CATEGORIES,
      categories: { name: `${type}_types`, data: fetchCategories.data },
    });
  } else if (category && categories) {
    categoryFound = categories[`${type}_types`].find(
      cat => cat.slug === category
    );
  }

  let getUrl = `${type}?lang=${lang}&per_page=${number}&page=${nextPage}`;
  let getType = type;

  if (categoryFound) {
    getUrl = `${type}?${type}_types=${categoryFound.id}&lang=${lang}&per_page=${number}&page=${nextPage}`;
  }

  if (categoryFound && type === 'products') {
    getUrl = `${type}/?category=${categoryFound.id}&lang=${lang}&per_page=${number}&page=${nextPage}`;
  }

  const response = await dataFetch(getType, `${getUrl}&status=publish`);

  dispatch({
    type: constants.GET_MORE_CONTENT,
    content: {
      content: response.data,
      page: nextPage,
      type,
    },
  });
};

/*
/ this gets the content for all list items
*/

const getListContent = async (response, lang, categoryFound, gotCategories) => {
  // first check if we have contetn
  if (
    !response ||
    !response.data ||
    !response.data[0] ||
    !response.data[0].acf ||
    !response.data[0].acf.content
  ) {
    return response;
  }

  // loop through content to find list items
  const content = response.data[0].acf.content.map(async ele => {
    if (
      ele.acf_fc_layout === 'content_list_alternate' ||
      ele.acf_fc_layout === 'content_list_highlight' ||
      ele.acf_fc_layout === 'content_list_map' ||
      ele.acf_fc_layout === 'content_list_gallery' ||
      ele.acf_fc_layout === 'content_list_grid_slideshow' ||
      ele.acf_fc_layout === 'content_list_text_slider' ||
      ele.acf_fc_layout === 'content_list_horizontal_image' ||
      ele.acf_fc_layout === 'categories_list_alternate' ||
      ele.acf_fc_layout === 'content_list_grid'
    ) {
      const type = ele.fields.content_type; // content type
      const number = ele.fields.number_of_items || config.listSize; // content type
      const showCategories = ele.fields.show_categories; // content type
      const showOnlyCategory = ele.fields.category_id;

      let fetchCategoryData = [];
      if (showCategories) {
        // lets get category if we need
        let catURl = `${type}_types?lang=${lang}`;

        if (type === 'products') {
          catURl = `products/categories?lang=${lang}&per_page=99`;
        }

        fetchCategoryData = !gotCategories.data
          ? await dataFetch(type, `${catURl}&status=publish`)
          : gotCategories;
      }

      //http://localhost:8085/wp-json/wp/v2/inspirations?inspirations_types=22
      // if we have a category selected we need to fech only posts from that category
      let getUrl = `${type}?lang=${lang}&per_page=${number}`;
      let getType = type;

      if (showOnlyCategory) {
        getUrl = `${type}?${type}_types=${showOnlyCategory.join(
          ','
        )}&lang=${lang}&per_page=${number}`;
      } else if (categoryFound) {
        getUrl = `${type}?${type}_types=${categoryFound.id}&lang=${lang}&per_page=${number}`;
      }

      if (categoryFound && type === 'products') {
        getUrl = `${type}/?category=${categoryFound.id}&lang=${lang}&per_page=${number}`;
      }

      // lets get the content for the list now
      const fetchData = await dataFetch(getType, `${getUrl}&status=publish`);
      // now lets update the content with the fectched list content
      if (fetchData && fetchData.data) {
        ele.fields.content = fetchData.data;
      }
      // now lets update the content with the fectched list content
      if (fetchCategoryData && fetchCategoryData.data) {
        ele.fields.categories = fetchCategoryData.data;
      }
    }
    return ele;
  });

  return Promise.all(content);
};

/*
/ this gets the category content
*/

export const getCategoryContent = (
  categoryId,
  type,
  lang,
  number,
  nextPage
) => async dispatch => {
  /**
   *  Now lets query on with our page request and pass in the cat id
   */
  dispatch({
    type: constants.GET_CATEGORY_CONTENT,
  });

  try {
    let getUrl = `${type}?${type}_types=${categoryId}&lang=${lang}&per_page=${number}&page=${nextPage}`;

    if (type === 'products') {
      getUrl = `${type}/?category=${categoryId}&lang=${lang}&per_page=${number}&page=${nextPage}`;
    }

    const response = await dataFetch(type, `${getUrl}&status=publish`);
    dispatch({
      type: constants.SET_CATEGORY_CONTENT,
      payload: { content: response, id: categoryId },
    });
  } catch (error) {
    console.log(error);
    // window.location = '/NotFound';
    dispatch({
      type: constants.SET_CATEGORY_CONTENT,
      payload: { content: {}, id: categoryId },
    });
  }
};

/*
/ this gets the content
*/

export const getContentPage = (
  slug = 'homepage',
  lang = config.defaultLang,
  type = 'pages',
  category = null,
  categories = null
) => async dispatch => {
  //http://localhost:8080/wp-json/wp/v2/landingpages?lang=de
  dispatch({
    type: constants.SET_CURRENT_CONTENT_REQUEST,
  });
  let response = {};
  let fetcheData = {};
  let fetchCategoryData = {};

  let categoryFound = null;
  // if we have a category selected we need to fech only posts from that category
  if (category && !categories) {
    fetchCategoryData = await getCategories(type, lang);
    categoryFound = fetchCategoryData.data.find(cat => cat.slug === category);
    // lets set the categories
    dispatch({
      type: constants.GET_CATEGORIES,
      categories: { name: `${type}_types`, data: fetchCategoryData.data },
    });
  } else if (category && categories) {
    categoryFound = categories[`${type}_types`].find(
      cat => cat.slug === category
    );
  }

  /**
   *  Now lets query on with our page request and pass in the cat id
   */
  try {
    let getUrl = `${type}?slug=${slug}&lang=${lang}`;
    let fetchType = type;

    if (categoryFound) {
      getUrl = `pages?slug=${type}&lang=${lang}`;
      fetchType = `pages`;
    }

    response = await dataFetch(fetchType, `${getUrl}&status=publish`);
    /**
     *  Now lets teh content inside the category
     */
    fetcheData = await getListContent(
      { ...response },
      lang,
      categoryFound,
      fetchCategoryData
    );

    if (response.data.length < 1) throw new Error('no content');
    // setup correct output join list content with reply
    dispatch({
      type: constants.SET_CURRENT_CONTENT,
      content: [
        {
          ...response.data[0],
          acf: {
            ...response.data[0].acf,
            content: fetcheData.data || fetcheData,
          },
        },
      ],
    });
  } catch (error) {
    console.log(error);
    // window.location = '/NotFound';
    // dispatch({
    //   type: constants.SET_CURRENT_CONTENT,
    //   content: {},
    // });
  }
};

/*
/ clear all content for proper page reload
*/

export const removeCurrentContent = () => dispatch =>
  new Promise(resolve => {
    // dispatch({
    //   type: constants.SET_CURRENT_CONTENT,
    //   content: [{ acf: {} }],
    // });

    resolve({});
  });
