import React from 'react';
import { Container } from 'reactstrap';
import Title from '../Title';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.css';
import Iframe from 'react-iframe';

function LayoutIframe(props) {
  const content = props.content;
  return (
    <div id={props.id} className={'layout-iframe'}>
      <Container>
        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
          <Title>{content.title}</Title>

          <Iframe
            url={content.source}
            width={content.width}
            height={content.height}
            className="iframe-content"
            display="initial"
            position="relative"
            allowFullScreen
          />
        </ScrollAnimation>
      </Container>
    </div>
  );
}

export default LayoutIframe;
