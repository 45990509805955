export const SET_CURRENT_CONTENT = 'content/SET_CURRENT_CONTENT_SUCCESS';
export const SET_CURRENT_CONTENT_REQUEST =
  'content/SET_CURRENT_CONTENT_REQUEST';
export const GET_MORE_CONTENT = 'content/GET_MORE_CONTENT_SUCCESS';
export const GET_MORE_CONTENT_REQUEST = 'content/GET_MORE_CONTENT_REQUEST';

export const GET_CATEGORIES_REQUEST = 'content/GET_CATEGORIES_REQUEST ';
export const GET_CATEGORIES = 'content/GET_CATEGORIES_SUCCESS';

export const GET_CATEGORY_CONTENT = 'content/GET_CATEGORY_CONTENT_REQUEST';
export const SET_CATEGORY_CONTENT = 'content/SET_CATEGORY_CONTENT_SUCCESS';
