export default function(storage) {
  try {
    if (
      !storage &&
      (typeof localStorage === 'undefined' || localStorage === null)
    )
      throw new Error('no local storage');
    const storage = window.localStorage;
    const key = 'd41d8cd98f00b204e9800998ecf8427e';
    storage.setItem(key, key);
    storage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

//isSupported(window.localStorage); // => true | false
