import { LANGUAGE_SET } from './constants';
import config from '../../../config';

const initialState = '';

const Language = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_SET:
      return action.lang || config.defaultLang;
    default:
      return state;
  }
};

export default Language;
