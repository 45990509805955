import React from 'react';
import { stack as Menu } from 'react-burger-menu';

import Language from '../../../containers/Language';
import { Link } from 'react-router-dom';
import { scrollToItem } from '../../../utils/scroll';
import './styles.scss';

function MobileMenu(props) {
  function scrollToReservation(link) {
    if (typeof window !== 'undefined') {
      const item = document.getElementById(link);
      scrollToItem(item, 150);
    }
  }

  function renderLink(col, index) {
    if (col.link[0] === '/') {
      return (
        <Link
          key={index}
          to={col.link}
          className={props.location === col.link ? 'active' : 'notactive'}
          dangerouslySetInnerHTML={{ __html: col.title }}
        />
      );
    } else if (!props.isHome) {
      return (
        <Link
          key={index}
          to={`/#${col.link}`}
          className={props.location === col.link ? 'active' : 'notactive'}
          dangerouslySetInnerHTML={{ __html: col.title }}
        />
      );
    } else {
      return (
        <a
          href={`#`}
          key={index}
          className={props.location === col.link ? 'active' : 'notactive'}
          onClick={() => scrollToReservation(col.link)}
          dangerouslySetInnerHTML={{ __html: col.title }}
        />
      );
    }
  }

  let logoImageUrl =
    props.options && props.options.logo_header && props.options.logo_header.url;

  if (props.fixed && props.options && props.options.logo_fixed)
    logoImageUrl = props.options.logo_fixed.url;

  return (
    <div className={'wrap-mobile'}>
      <div className={'logo'}>
        <Link
          to={'/'}
          style={
            logoImageUrl && {
              backgroundImage: `url('${logoImageUrl}')`,
            }
          }
        />
      </div>
      <Menu right width={240}>
        <Language lang={props.lang} />
        {props.submenu &&
          props.submenu.reverse().map((col, index) => renderLink(col, index))}
        {props.menu &&
          props.menu.reverse().map((col, index) => renderLink(col, index))}
      </Menu>
    </div>
  );
}

export default MobileMenu;
