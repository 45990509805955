import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import Button from '../Button';
import './styles.scss';

function AlternateHorizontalTexBlock(props) {
  const content = props.content;
  return (
    <div
      className={`content-blocks-alternate ${props.first && 'first'} ${
        props.odd === 1 ? 'odd' : 'even'
      } clearfix`}
    >
      <Container>
        <div
          className={`blocks-image`}
          style={{ backgroundImage: `url(${content.image.url})` }}
        />
        {content.block &&
          content.block.map((cont, index) => {
            return (
              <div key={index} className={`blocks block_${index}`}>
                <h5 dangerouslySetInnerHTML={{ __html: cont.title }} />
                <div
                  className={`blocks-content`}
                  dangerouslySetInnerHTML={{ __html: cont.content }}
                />
                <span className={'blocks-line'} />
                {cont.button_text && (
                  <Button className={'blocks-action'} to={cont.button_link}>
                    {cont.button_text}
                  </Button>
                )}
              </div>
            );
          })}
      </Container>
    </div>
  );
}

export default AlternateHorizontalTexBlock;
