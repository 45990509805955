import * as constants from './constants';
import isSupported from '../../utils/localStorage';

let initialState = {
  options: {
    contact_fields: {},
    content: {},
    gdrp: {},
  },
};
let newState = {};
//lets load user data for reload
const isLocalStorage = isSupported();
if (isLocalStorage && localStorage.options) {
  initialState = JSON.parse(localStorage.options);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_CURRENT_OPTIONS:
      newState = {
        ...state,
        options: action.options,
      };
      if (isLocalStorage) localStorage.options = JSON.stringify(newState);
      return newState;
    default:
      return state;
  }
};
