import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import ReadMore from '../ReadMore';
import './styles.scss';

function LayoutContentBackground(props) {
  return (
    <div
      id={props.id}
      className={`${props.first ? 'first' : ''} ${
        props.content.background_image
          ? 'text-block-background'
          : 'horizontal-text'
      }`}
      style={{
        backgroundImage: `url('${props.content.background_image &&
          props.content.background_image.sizes.large}')`,
        backgroundColor: `${props.content.background_color || '#fff'}`,
      }}
    >
      <Container>
        <Row>
          <Col xs="12" sm="12" md={'6'} className={' title'}>
            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              <h5
                style={{
                  color: `${props.content.text_color || '#104530'}`,
                }}
              >
                {props.content.title}
              </h5>
            </ScrollAnimation>
          </Col>

          <Col
            xs="12"
            sm="12"
            md={'6'}
            className={'align-items-center align-self-center '}
          >
            <ScrollAnimation
              animateIn="fadeIn"
              delay={150}
              animateOut="fadeOut"
            >
              <div
                style={{
                  color: `${props.content.text_color || '#104530'}`,
                }}
                className={'text'}
                dangerouslySetInnerHTML={{ __html: props.content.text }}
              />
              {props.content.link && (
                <ReadMore
                  style={{
                    color: `${props.content.text_color || '#104530'}`,
                  }}
                  link={props.content.link}
                >
                  {props.content.button_text}
                </ReadMore>
              )}
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LayoutContentBackground;
