import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Input } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import Title from '../Title';
import './styles.css';

export default class LayoutColumsGroup extends Component {
  constructor(props) {
    super(props);
    this.gotoLink = this.gotoLink.bind(this);
  }

  renderElements(elements) {
    return elements.map((element, index) => (
      <div
        key={index}
        className={`elemnt  ${element.link && 'link'}`}
        onClick={() => this.gotoLink(element.link)}
      >
        {element.image && (
          <span
            className={`image`}
            style={{ backgroundImage: `url(${element.image.sizes.medium})` }}
          />
        )}
        <h6 className={'title'}>{element.title}</h6>
        <p
          className={'description'}
          dangerouslySetInnerHTML={{ __html: element.content }}
        />
      </div>
    ));
  }

  gotoLink(url) {
    if (window && url) window.location = url;
  }

  renderCards() {
    let thisRow = [];
    let globalMenu = [];
    let i = 0;

    this.props.content.column.map((item, itemIndex) => {
      //lets wrap conten and clear it
      if (i === 3) {
        globalMenu.push(
          <div key={`row Row-${itemIndex} columnGroupWrap`}>{thisRow}</div>
        );
        thisRow = [];
        i = 0;
      }

      //lets push content into row
      thisRow.push(
        <div className={'columnGroup'} key={itemIndex}>
          <div
            className={`menu-item fadeInUp animated`}
            style={{
              animationDelay: `${i / 2 + 0.01}s`,
              animationDuration: `1s`,
            }}
          >
            <div className={`item-over ${!item.logo ? 'noImage' : ''}`}>
              <div>
                {item.logo && (
                  <img
                    className={`logo`}
                    src={item.logo.sizes.medium}
                    alt={item.logo.alt}
                  />
                )}
                <h6 className={'title'}>{item.title}</h6>
                <p
                  className={'description'}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                {item.elements && this.renderElements(item.elements)}
              </div>
            </div>
          </div>
        </div>
      );
      i++;
    });

    //if there is something left in rows lets push it to global, object
    if (thisRow.length > 0) {
      globalMenu.push(<div className={`row columnGroupWrap`}>{thisRow}</div>);
    }
    // now we return the global object
    return globalMenu;
  }

  render() {
    const { title } = this.props.content;
    return (
      <div className={'columns-group'}>
        <Title>{title}</Title>
        <Container className={'container'}>{this.renderCards()}</Container>
      </div>
    );
  }
}
