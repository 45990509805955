import React from 'react';
import './styles.scss';
import ScrollAnimation from 'react-animate-on-scroll';

function Title(props) {
  if (!props.title && !props.subtitle) return null;
  return (
    <div className={'section-title'}>
      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" delay={100}>
        <div className={'hr'} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" delay={200}>
        <h2>
          <span>{props.subtitle}</span>
          {props.title}
        </h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" delay={300}>
        <div className={'hr'} />
      </ScrollAnimation>
    </div>
  );
}

export default Title;
