import React from 'react';
import 'leaflet/dist/leaflet.css';
import './styles.css';
import { Link } from 'react-router-dom';
import config from '../../../config';

function LayoutListMap(props) {
  const content = props.content;
  const state = {
    lat: 38.714951,
    lng: -9.07744,
    zoom: 6,
  };
  const position = [state.lat, state.lng];

  const points =
    content.content.reduce((prev, curr) => {
      const mapCtype = {
        title: curr.title.rendered,
        image: curr.acf.excerpt.image,
        content: curr.acf.excerpt.summary,
        link: curr.link,
        map:
          curr.acf &&
          curr.acf.content &&
          curr.acf.content.filter(type => type.acf_fc_layout === 'map')[0],
      };
      return [...prev, mapCtype];
    }, []) || [];

  if (process.title == 'browser') {
    // SSR loading problem
    var { Map, TileLayer, CircleMarker, Popup } = require('react-leaflet');

    return (
      <div id={props.id} className={'list-map'}>
        <Map center={position} zoom={state.zoom}>
          <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {points.map((point, i) => {
            return (
              point.map.fields.geolocation &&
              point.map.fields.geolocation.lat &&
              point.map.fields.geolocation.lng && (
                <CircleMarker
                  key={i}
                  center={[
                    point.map.fields.geolocation.lat,
                    point.map.fields.geolocation.lng,
                  ]}
                  radius={20}
                  border={5}
                >
                  <Popup>
                    <h6>{point.title}</h6>
                    <p dangerouslySetInnerHTML={{ __html: point.content }} />
                    <Link to={point.link.replace(config.websiteURL, '')}>
                      Ver mais
                    </Link>
                  </Popup>
                </CircleMarker>
              )
            );
          })}
        </Map>
      </div>
    );
  } else {
    return null;
  }
}

export default LayoutListMap;
