import React, { Component } from 'react';
import './styles.css';
import config from '../../../../config';

export default class LoadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reachedBottom: false,
      loading: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  getMoreContent(content) {
    const currentPage = content.page || 1;
    // first check if we have more content
    const moreContent =
      content.load_more &&
      content.content &&
      content.content.length >= config.listSize * currentPage;

    // let check if we are already loading
    if (
      moreContent &&
      this.state.reachedBottom &&
      !this.state.loading &&
      content.load_more
    ) {
      this.setState({ loading: true });
      this.props.loadMoreContentPage(content.content_type, currentPage);
      return null;
    } else if (moreContent && !this.state.loading && content.load_more) {
      return (
        <div className={'loadmore-wrap'}>
          <a
            className={'loadmore'}
            onClick={() =>
              this.props.loadMoreContentPage(content.content_type, currentPage)
            }
          >
            {content.load_more || 'Load more'}{' '}
            <i className={'icons icon-arrow-down'} />
          </a>
        </div>
      );
    } else {
      return null;
    }
  }

  handleScroll() {
    if (typeof window === 'undefined') return null;
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight && !this.state.reachedBottom) {
      this.setState({ reachedBottom: true });
    } else if (windowBottom <= docHeight && this.state.reachedBottom) {
      this.setState({ reachedBottom: false });
    }
    return null;
  }

  componentDidMount() {
    typeof window !== 'undefined' &&
      window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    typeof window !== 'undefined' &&
      window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    // lets reset to allow to load more content on reach bottom
    if (
      (this.props.status === 'set' || this.props.status === 'updated') &&
      this.state.loading
    ) {
      this.setState({ loading: false });
    }
  }

  render() {
    if (!this.props.content) return null;
    return this.getMoreContent(this.props.content);
  }
}
