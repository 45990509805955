import React, { Component } from 'react';
import { Container, Row, Col, Form, Input, Button } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.css';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

export default class LayoutNewsletter extends Component {
  constructor(props) {
    super(props);

    let newsletter = {
      subscribed: false,
    };

    if (typeof localStorage !== 'undefined') {
      newsletter = JSON.parse(localStorage['newsletter'] || null) || newsletter;
    }

    this.state = {
      newsletter,
    };

    this.onAccept = this.onAccept.bind(this);
  }

  onAccept() {
    const newState = { newsletter: { subscribed: true } };

    if (typeof localStorage !== 'undefined') {
      localStorage['newsletter'] = JSON.stringify(newState.newsletter);
    }
    this.setState(newState);
  }

  CustomForm(status, message, onValidated) {
    let email, name;
    const submit = () => {
      if (email && name && email.value.indexOf('@') > -1) {
        onValidated({
          EMAIL: email.value,
          FNAME: name.value,
        });
        this.onAccept();
      }
    };
    return (
      <div className={'formHolder'}>
        {status !== 'success' && (
          <div>
            <input
              ref={node => (name = node)}
              type="text"
              placeholder={this.props.content.name}
            />
            <input
              ref={node => (email = node)}
              type="email"
              placeholder={this.props.content.email}
            />
            <Button onClick={submit}>
              {status === 'sending'
                ? 'sending...'
                : this.props.content.button_text}
            </Button>
          </div>
        )}
        {status === 'error' && (
          <div
            style={{ color: '#fff' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === 'success' && (
          <div
            style={{ color: '#fff' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    );
  }

  render() {
    let body = {};
    const url =
      'https://backtonature.us18.list-manage.com/subscribe/post?u=7801004b044a12ba1b6e2dbf4&amp;id=2c407d8701';
    // 'https://reactorhub.us18.list-manage.com/subscribe/post?u=f8ae19897273a7ebf80521f44&amp;id=8a0cdbc2b8';

    return (
      <div
        className={`newsletter ${
          this.props.content.background_image
            ? 'text-block-newsletter'
            : 'horizontal-newsletter'
        }`}
        style={{
          backgroundImage: `url('${this.props.content.background_image &&
            this.props.content.background_image.sizes.large}')`,
          backgroundColor: `${this.props.content.background_color ||
            '#8eca80'}`,
        }}
      >
        <Container>
          <Row>
            <Col xs="12" sm="12" md={'12'} className={'title'}>
              <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
                <MailchimpSubscribe
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <div>
                      <Row>
                        <Col xs="12">
                          {/* <Col xs="12" md={{ size: 8, offset: 2 }}> */}
                          <h5
                            dangerouslySetInnerHTML={{
                              __html: this.props.content.title,
                            }}
                            style={{
                              color: `${this.props.content.text_color ||
                                '#ffffff'}`,
                            }}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.props.content.text,
                            }}
                            style={{
                              color: `${this.props.content.text_color ||
                                '#ffffff'}`,
                            }}
                          />
                          {this.CustomForm(status, message, formData =>
                            subscribe(formData)
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
