import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import LayoutGridElement from '../LayoutGridElement';
import LayoutGridElementHover from '../LayoutGridElementHover';
import Title from '../Title';

import config from '../../../config';

import ScrollAnimation from 'react-animate-on-scroll';
import './styles.scss';

export default class LayoutListContentGrid extends Component {
  constructor(props) {
    super(props);
  }

  renderCards() {
    let thisRow = [];
    let globalMenu = [];
    let i = 0;
    this.props.content.content.map((content, itemIndex) => {
      const item = content.acf.excerpt;

      let elementData = {
        type: this.props.content.content_type,
        image: item.image,
        link: content.link.replace(config.websiteURL, ''),
        title: content.title.rendered,
        content: item.summary,
        button_text: this.props.content.button_text,
      };
      //lets push content into row
      thisRow.push(
        <Col key={itemIndex} xs="12" md="4">
          {/* <div
            className={'fadeInUp animated'}
            style={{
              animationDelay: `${i / 2 + 0.03}s`,
              animationDuration: `1s`,
            }}
          > */}
          {this.props.content.element_layout === 'content_under' ? (
            <LayoutGridElement {...elementData} />
          ) : (
            <LayoutGridElementHover {...elementData} />
          )}
          {/* </div> */}
        </Col>
      );
      i++;
    });

    // now we return the global object
    return <Row key={`Row`}>{thisRow}</Row>;
  }

  render() {
    const { content, title, sub_title, background_colour } = this.props.content;
    return (
      <div
        className={'list-grid'}
        id={title.toLowerCase().replace(/ /g, '_')}
        style={{ backgroundColor: background_colour }}
      >
        <Container className={'container-uptop'}>
          <Title title={title} subtitle={sub_title} />
          {this.renderCards()}
        </Container>
      </div>
    );
  }
}
