import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SVG from 'react-inlinesvg';
import ScrollAnimation from 'react-animate-on-scroll';
import { excerptFrom } from '../../utils/strings/excerpt';

import './styles.css';

export default class LayoutTabsHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      updateCount: 0,
    };
  }

  render() {
    const { features } = this.props.content;
    const feature = features.find(
      (feature, index) => index === this.state.slideIndex
    );
    return (
      <div className={'features'}>
        <div className={'circle-wrapper'}>
          <div className={'circle-blue'} />
        </div>
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
            <div className={'slide clearfix'}>
              <div className={'image'}>
                <img src={feature.image.url} alt={feature.image.alt} />
              </div>
              <div className={'content'}>
                <h3>{feature.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: feature.content }} />
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
            <div className={'pager'}>
              {features.map((feature, index) => (
                <div
                  key={index}
                  onMouseOver={e => this.setState({ slideIndex: index })}
                  className={`item ${
                    index === this.state.slideIndex ? 'active' : ''
                  }`}
                >
                  <div className={'image'}>
                    <img src={feature.icon.url} alt={feature.icon.alt} />
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: feature.title }} />
                  <p
                    className={'content'}
                    dangerouslySetInnerHTML={{
                      __html: excerptFrom(60, feature.content),
                    }}
                  />
                </div>
              ))}
            </div>
          </ScrollAnimation>
        </Container>
      </div>
    );
  }
}
