import config from '../../../config';
import { http } from '../../utils/OfflineAxios';

export const dataFetch = async (type, getUrl) => {
  return http.get(`${config.apiURL}/wp-json/wp/v2/${getUrl}`, {
    withCredentials: true,
  });
};

export const dataPostFetch = async (type, getUrl, data) => {
  return http.post(`${config.apiURL}/wp-json/wp/v2/${getUrl}`, data, {
    withCredentials: true,
  });
};
