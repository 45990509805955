import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.css';
import Title from '../Title';

const Show = props => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

function LayoutIconTextRow(props) {
  const { column } = props.content;
  return (
    <div
      className={`icon-row ${props.first ? 'first' : ''} ${
        props.content.background ? 'color' : ''
      }`}
    >
      <div className={'content-container'}>
        <Container>
          <Title>{props.content.title}</Title>
          {column.map((row, i) => {
            return (
              <a
                href={row.link !== '' ? row.link : '#'}
                target={row.link !== '' ? '_blank' : '_self'}
                className={'content-wrap'}
                key={i}
              >
                {row && (
                  <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
                    {row.icon && (
                      <div
                        className={'image'}
                        style={{
                          backgroundImage: `url('${row.icon.url}')`,
                        }}
                      />
                    )}
                    <div className={'text'}>
                      <h3>{row.title}</h3>
                      <p dangerouslySetInnerHTML={{ __html: row.content }} />
                    </div>
                  </ScrollAnimation>
                )}
              </a>
            );
          })}
        </Container>
      </div>
    </div>
  );
}

export default LayoutIconTextRow;
