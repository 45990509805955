import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Title from '../Title';
import './styles.scss';

function LayoutTitle(props) {
  const content = props.content;
  if (!content) return null;
  return (
    <div
      id={content.title.toLowerCase().replace(/ /g, '_')}
      className={`content-title`}
    >
      <Container>
        <Title title={content.title} subtitle={content.sub_title} />
      </Container>
    </div>
  );
}

export default LayoutTitle;
