import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import Title from '../Title';

import './styles.css';

export default class LayoutContentPager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
    };
  }

  render() {
    const content = this.props.content.item;
    const team = content.find((team, index) => index === this.state.slideIndex);
    return (
      <div className={'teams'}>
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
            <Title>{this.props.content.title}</Title>
            <div className={'slide clearfix'}>
              <div className={'content'}>
                <h3>{team.title}</h3>
                <p
                  className={'position'}
                  dangerouslySetInnerHTML={{ __html: team.sub_title }}
                />
                <p>
                  {team.facebook && (
                    <a
                      className={'social'}
                      href={team.facebook}
                      target="_blank"
                    >
                      <i className={'icons icon-social-facebook'} />
                    </a>
                  )}
                  {team.linkedin && (
                    <a
                      className={'social'}
                      href={team.linkedin}
                      target="_blank"
                    >
                      <i className={'icons icon-social-linkedin'} />
                    </a>
                  )}
                </p>
                <p dangerouslySetInnerHTML={{ __html: team.content }} />
              </div>
              <div className={'image'}>
                <img src={`${team.image.url}`} alt={team.image.alt} />
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
            <div className={'pager'}>
              {content.map((team, index) => (
                <div
                  key={index}
                  onMouseOver={e => this.setState({ slideIndex: index })}
                  className={`item ${
                    index === this.state.slideIndex ? 'active' : ''
                  }`}
                >
                  <div
                    className={'image'}
                    style={{
                      backgroundImage: `url(${team.image.url})`,
                    }}
                  />
                  <p dangerouslySetInnerHTML={{ __html: team.title }} />
                  <p
                    className={'position'}
                    dangerouslySetInnerHTML={{ __html: team.sub_title }}
                  />
                </div>
              ))}
            </div>
          </ScrollAnimation>
        </Container>
      </div>
    );
  }
}
