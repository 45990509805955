import React from 'react';
import LayoutAlternateContent from '../LayoutAlternateContent';
import './styles.css';

function LayoutListContentAlternate(props) {
  const content = props.content;

  if (!content || !content.categories) return <div />;

  return content.categories.map((cont, i) => {
    // if (!cont.acf.excerpt) return false;
    return (
      <LayoutAlternateContent
        key={i}
        odd={i % 2}
        content={{
          title: cont.name,
          content: cont.description,
          image: cont.image,
          link: `/${props.language}/${content.content_type}/types/${cont.slug}`,
          button_text: content.button_text,
        }}
      />
    );
  });
}

export default LayoutListContentAlternate;
