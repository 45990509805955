import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.scss';
import ReactPlayer from 'react-player';

function LayoutAlternatePerson(props) {
  function renderImage() {
    return (
      <Col
        xs="12"
        md="6"
        className={`clearfix imageContent ${
          props.content.image.url || props.content.image.src ? '' : 'noimage'
        }`}
        style={{
          backgroundImage: `url(${props.content.image.url ||
            props.content.image.src})`,
        }}
      >
        <h5 dangerouslySetInnerHTML={{ __html: props.content.name }} />
      </Col>
    );
  }

  function renderText() {
    return (
      <div className={'content-wrap'}>
        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
          <h5 dangerouslySetInnerHTML={{ __html: props.content.name }} />
          {props.content.sub_title && (
            <h6
              dangerouslySetInnerHTML={{
                __html: props.content.sub_title,
              }}
            />
          )}

          <p
            className={'content-role'}
            dangerouslySetInnerHTML={{ __html: props.content.role }}
          />
          <div dangerouslySetInnerHTML={{ __html: props.content.content }} />
        </ScrollAnimation>
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className={`horizontal-person-block-background ${
        props.odd === 1 ? 'odd' : 'even'
      } ${props.first ? 'first' : ''} ${
        props.content.with_background ? 'color' : 'white'
      }`}
    >
      <Container>
        <Row>
          {!props.odd && renderImage()}

          <Col
            xs="12"
            md="6"
            className={'content align-items-center align-self-center'}
          >
            {renderText()}
          </Col>
          {props.odd === 1 && renderImage()}
        </Row>
      </Container>
    </div>
  );
}

export default LayoutAlternatePerson;
