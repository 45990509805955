import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Slider from 'react-slick';
import Title from '../Title';
import Button from '../Button';
import ScrollAnimation from 'react-animate-on-scroll';
import { excerptFrom } from '../../utils/strings/excerpt';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

export default class LayoutHorizontalGallery extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const { slides } = this.props.content;

    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 800,
      autoplay: slides.length > 1,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
    };
    return (
      <div className={'section-horizontal-gallery-wrap'}>
        <Container>
          <div className={'section-horizontal-gallery'}>
            <ScrollAnimation
              animateIn="fadeIn"
              delay={100}
              animateOut="fadeOut"
            >
              <Slider ref={c => (this.slider = c)} {...settings}>
                {slides.map((slide, index) => (
                  <div key={index}>
                    <div
                      className={'content-image'}
                      style={{
                        backgroundImage: `url('${slide.image.url}')`,
                      }}
                    />
                    <Container className={'content-placeholder'}>
                      <h5>{slide.title}</h5>
                      <p>{slide.content}</p>
                      <span className={'blocks-line'} />
                      {slide.button && (
                        <Button className={'action'} to={slide.button_link}>
                          {slide.button}
                        </Button>
                      )}
                    </Container>
                  </div>
                ))}
              </Slider>
            </ScrollAnimation>
          </div>
        </Container>
      </div>
    );
  }
}
