import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './styles.scss';
import Menu from '../Menus/Menu';
import LayoutLogoRow from '../LayoutLogoRow';

export default class Contact extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (!this.props.content) return null;
    return (
      <div className={'footer'}>
        <Container>
          <Row>
            <Col xs="12" md="6">
              {this.props.content.logo_footer && (
                <div
                  className={'logo'}
                  style={{
                    backgroundImage: `url(${this.props.content.logo_footer.url})`,
                  }}
                ></div>
              )}
            </Col>

            <Col xs="12" md="3">
              {this.props.content.content_one && (
                <div
                  className={'content-wrapper'}
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.content_one,
                  }}
                />
              )}
            </Col>
            <Col xs="12" md="3">
              {this.props.content.content_two && (
                <div
                  className={'content-wrapper'}
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.content_two,
                  }}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
