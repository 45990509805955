import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { connectRouter } from 'connected-react-router';

import content from '../app/containers/Content/Reducer';
import menus from '../app/containers/Menus/Reducer';
import options from '../app/containers/Options/Reducer';
import language from '../app/containers/Language/Reducer';
import search from '../app/containers/Search/Reducer';
import admin from '../app/containers/Admin/Reducer';

const routeReducer = history =>
  combineReducers({
    router: connectRouter(history),
    menus,
    content,
    options,
    language,
    admin,
    search,
    loadingBar: loadingBarReducer,
  });

export default routeReducer;
