import React, { Component } from 'react';
import { Container, Row, Col, Collapse } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import Title from '../Title';
import './styles.css';

export default class LayoutContentAccordian extends Component {
  constructor(props) {
    super(props);

    let items = [...props.content.content];
    const itemsLenght = items.length;
    const half_length = Math.ceil(itemsLenght / 2);
    const itemsLeft = items.splice(half_length, itemsLenght);

    this.state = {
      rightObject: items,
      leftObject: itemsLeft,
      selected: 'l0',
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse(i) {
    this.setState({ selected: i });
  }

  render() {
    return (
      <Container className={'layout-content-accordian'}>
        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutUp">
          <Title>{this.props.title}</Title>
        </ScrollAnimation>
        <Row>
          <Col md={6} xs={12}>
            {this.state.rightObject.map((item, index) => (
              <ScrollAnimation
                key={index}
                animateIn="fadeInUp"
                delay={index * 30}
                animateOut="fadeOut"
              >
                <div className={'item'}>
                  <p onClick={() => this.toggleCollapse(`l${index}`)}>
                    <span>{item.title}</span>
                  </p>
                  <Collapse
                    className={'content'}
                    isOpen={this.state.selected === `l${index}`}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              </ScrollAnimation>
            ))}
          </Col>
          <Col md={6} xs={12}>
            {this.state.leftObject.map((item, index) => (
              <ScrollAnimation
                key={index}
                animateIn="fadeInUp"
                delay={index * 30}
                animateOut="fadeOut"
              >
                <div className={'item'}>
                  <p onClick={() => this.toggleCollapse(`r${index}`)}>
                    <span>{item.title}</span>
                  </p>
                  <Collapse
                    className={'content'}
                    isOpen={this.state.selected === `r${index}`}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              </ScrollAnimation>
            ))}
          </Col>
        </Row>
      </Container>
    );
  }
}
