import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import ReadMore from '../ReadMore';
import './styles.css';

const featureElment = (props, i) => (
  <ScrollAnimation
    key={i}
    animateIn="fadeIn"
    delay={i * 20 + 100}
    animateOut="fadeOut"
  >
    <div className={'feature'}>
      <div className={'icon'}>
        <i className={`icons icon-${props.icon}`} />
      </div>
      <div className={'title'}> {props.title}</div>
      <div className={'text'}> {props.text}</div>
    </div>
  </ScrollAnimation>
);

function LayoutFeaturesColumns(props) {
  const content = props.content;

  let items = [...props.content.features];

  const itemsLenght = items.length;
  const half_length = Math.ceil(itemsLenght / 2);
  const itemsLeft = items.splice(half_length, itemsLenght);

  return (
    <div className={`feature-columns ${props.first && 'first'}`}>
      <Container>
        <Row>
          <Col xs="12">
            <h3 className={'section-title'}>{content.title}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="3">
            <div className={'column-left'}>
              {itemsLeft.map((item, i) => featureElment(item, `a${i}`))}
            </div>
          </Col>
          <Col xs="12" md="1" />
          <Col
            xs="12"
            md="4"
            className={'align-items-center align-self-center'}
          >
            <ScrollAnimation animateIn="fadeIn" delay={50} animateOut="fadeOut">
              <div className={'image-background'}>
                <img
                  src={content.image.sizes && content.image.sizes.large}
                  alt={content.image.alt}
                />
              </div>
            </ScrollAnimation>
          </Col>
          <Col xs="12" md="1" />
          <Col xs="12" md="3">
            <div className={'column-right'}>
              {items.map((item, i) => featureElment(item, `b${i}`))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LayoutFeaturesColumns;
