import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import ReadMore from '../ReadMore';

function convertNumberToInch(number) {
  return (number * 0.39370078740157).toFixed(2);
}

function LayoutGridElement(props) {
  const {
    image,
    link,
    title,
    content,
    button_text,
    dimensions,
    type,
    acf,
    price,
    currency_symbol,
  } = props;

  return (
    <div className={'grid-element'}>
      <Link
        to={link}
        className={'image'}
        style={{
          backgroundImage: `url('${image.sizes && image.sizes.large}')`,
        }}
      />
      <div className={'content'}>
        <h6 dangerouslySetInnerHTML={{ __html: title }} />
        {/* <p>{content}</p> */}

        {dimensions && (
          <div className={'dimensions'}>
            <div className={'unit'}>
              {dimensions.height && <span>H: {dimensions.height}cm</span>}
              {dimensions.length && <span>L: {dimensions.length}cm</span>}
              {dimensions.width && <span>W: {dimensions.width}cm</span>}
            </div>
            <div className={'unit'}>
              {dimensions.height && (
                <span>H: {convertNumberToInch(dimensions.height)}"</span>
              )}
              {dimensions.length && (
                <span>L: {convertNumberToInch(dimensions.length)}"</span>
              )}
              {dimensions.width && (
                <span>W: {convertNumberToInch(dimensions.width)}"</span>
              )}
            </div>
          </div>
        )}

        {price && button_text && (
          <div className={'price'}>
            <span dangerouslySetInnerHTML={{ __html: currency_symbol }} />{' '}
            {price}
          </div>
        )}
        {button_text && <ReadMore link={link}>{button_text}</ReadMore>}
      </div>
    </div>
  );
}

export default LayoutGridElement;
