import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

function convertNumberToInch(number) {
  return (number * 0.39370078740157).toFixed(2);
}

function LayoutGridElementHover(props) {
  const {
    id,
    image,
    link,
    title,
    content,
    dimensions,
    button_text,
    price,
    currency_symbol,
    addProductToCart,
  } = props;
  console.log(price);
  return (
    <div
      className={'grid-element-hover'}
      style={{
        backgroundImage: `url('${image.sizes && image.sizes.medium}')`,
      }}
    >
      <div className={'over'}>
        <div>
          <Link to={link}>
            <h6>{title}</h6>
            <div>{content}</div>
            {dimensions && (
              <div className={'dimensions'}>
                <div className={'unit'}>
                  {dimensions.height && <span>H: {dimensions.height}cm</span>}
                  {dimensions.length && <span>L: {dimensions.length}cm</span>}
                  {dimensions.width && <span>W: {dimensions.width}cm</span>}
                </div>
                <div className={'unit'}>
                  {dimensions.height && (
                    <span>H: {convertNumberToInch(dimensions.height)}"</span>
                  )}
                  {dimensions.length && (
                    <span>L: {convertNumberToInch(dimensions.length)}"</span>
                  )}
                  {dimensions.width && (
                    <span>W: {convertNumberToInch(dimensions.width)}"</span>
                  )}
                </div>
              </div>
            )}

            {/* HIDE THIS FOR NOW {price &&
              button_text && (
                <div className={'price'}>
                  <span dangerouslySetInnerHTML={{ __html: currency_symbol }} />{' '}
                  {price}
                </div>
              )} */}
          </Link>
          {/*  HIDE THIS FOR NOW {button_text &&
            price && (
              <a className={'action'} onClick={() => addProductToCart(id, 1)}>
                {button_text}
              </a>
            )} */}
          {button_text && (
            <Link to={link} className={'action'}>
              {button_text}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default LayoutGridElementHover;
