import config from '../../../config';
import * as constants from './constants';
import { dataFetch } from '../Fetch';

/**
 *  seach for term
 */
export const getSearch = (term, lang, page) => async dispatch => {
  try {
    dispatch({
      type: constants.GET_SEARCH,
    });
    ///wp/v2/search/Amazon
    const response = await dataFetch(
      'search',
      `search/${term.split(' ').join('+')}/${page ||
        1}?lang=${lang}&status=publish`
    );

    dispatch({
      type: constants.SET_SEARCH,
      data: { page: page, results: response.data },
    });
  } catch (error) {
    console.log(error);
    // dispatch({
    //   type: constants.GET_CATEGORIES,
    // });
  }
};

/**
 *  seach display
 */
export const toggleSearch = () => async dispatch => {
  dispatch({
    type: constants.TOGGLE_SEARCH,
  });
};
