import React, { Component } from 'react';
import Title from '../Title';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'reactstrap';
import './styles.css';

export default class LayoutContentCenter extends Component {
  render() {
    // const { steps, currentStep } = this.state;
    const { content } = this.props;
    return (
      <div className={'content-center'}>
        <Container>
          <ScrollAnimation
            animateIn="fadeInUp"
            delay={100}
            animateOut="fadeOut"
          >
            <div
              className={'content'}
              dangerouslySetInnerHTML={{ __html: content.content }}
            />
          </ScrollAnimation>
        </Container>
      </div>
    );
  }
}
