import React, { Component } from 'react';

// Menus
import LoadButton from '../Menus/LoadButton';
import Categories from '../Menus/Categories';

// templates blocks
import LayoutButton from '../LayoutButton';
import LayoutTitle from '../LayoutTitle';
import LayoutMenu from '../LayoutMenu';
import LayoutContent from '../LayoutContent';
import LayoutTabsHorizontal from '../LayoutTabsHorizontal';
import LayoutGalleryHorizontal from '../LayoutGalleryHorizontal';
import LayoutContentSlider from '../LayoutContentSlider';
import LayoutTimeline from '../LayoutTimeline';
import LayoutContentPager from '../LayoutContentPager';
import LayoutIconTextRow from '../LayoutIconTextRow';
import LayoutContactMap from '../LayoutContactMap';
import LayoutContentFilter from '../LayoutContentFilter';
import LayoutContentAccordian from '../LayoutContentAccordian';
import LayoutContentBackground from '../LayoutContentBackground';
import LayoutIntro from '../LayoutIntro';
import LayoutAlternateContent from '../LayoutAlternateContent';
import LayoutAlternatePerson from '../LayoutAlternatePerson';
import LayoutAlternateContentGraph from '../LayoutAlternateContentGraph';
import LayoutFeaturesColumns from '../LayoutFeaturesColumns';
import LayoutContactForm from '../LayoutContactForm';
import LayoutContentColumns from '../LayoutContentColumns';
import LayoutContentCenter from '../LayoutContentCenter';
import LayoutIframe from '../LayoutIframe';
import LayoutNewsletter from '../LayoutNewsletter';
import LayoutContentSocial from '../LayoutContentSocial';
import LayoutMap from '../LayoutMap';
import LayoutColumsGroup from '../LayoutColumsGroup';
import LayoutContentSidebySide from '../LayoutContentSidebySide';
import LayoutContentBlocks from '../LayoutContentBlocks';
import AlternateHorizontalTexBlock from '../AlternateHorizontalTexBlock';

// list templates
import LayoutListContentAlternate from '../LayoutListContentAlternate';
import LayoutListContentTextSlideshow from '../LayoutListContentTextSlideshow';
import LayoutListCategoryAlternate from '../LayoutListCategoryAlternate';
import LayoutListContentAlternateBackground from '../LayoutListContentAlternateBackground';
import LayoutListContentGrid from '../LayoutListContentGrid';
import LayoutListHighlight from '../LayoutListHighlight';
import LayoutListMap from '../LayoutListMap';
import LayoutListGallery from '../LayoutListGallery';
import LayoutListContentGridSlideshow from '../LayoutListContentGridSlideshow';

export default class DynamicContent extends Component {
  constructor(props) {
    super(props);
  }

  renderContent(component, content, i, language, status) {
    let defaultProps = { key: i, first: i === 0, content, odd: i % 2 };
    switch (component) {
      case 'button':
        return <LayoutButton {...defaultProps} />;
      case 'title':
        return <LayoutTitle {...defaultProps} />;
      case 'menu':
        return <LayoutMenu {...defaultProps} />;
      case 'content':
        return <LayoutContent {...defaultProps} />;
      case 'content_side_by_site':
        return <LayoutContentSidebySide {...defaultProps} />;
      case 'content_blocks':
        return <LayoutContentBlocks {...defaultProps} />;
      case 'content_blocks_alternate':
        return <AlternateHorizontalTexBlock {...defaultProps} />;
      case 'horizontal_tabs':
        return <LayoutTabsHorizontal {...defaultProps} />;
      case 'horizontal_slideshow':
        return <LayoutGalleryHorizontal {...defaultProps} />;
      case 'content_timeline':
        return <LayoutTimeline {...defaultProps} />;
      case 'image_content_pager':
        return <LayoutContentPager {...defaultProps} />;
      case 'icon_text_row':
        return <LayoutIconTextRow {...defaultProps} />;
      case 'map_contact':
        return <LayoutContactMap {...defaultProps} />;
      case 'content_filter':
        return <LayoutContentFilter {...defaultProps} />;
      case 'intro_block':
        return <LayoutIntro {...defaultProps} />;
      case 'content_highlight':
        return <LayoutContentBackground {...defaultProps} />;
      case 'content_social':
        return <LayoutContentSocial {...defaultProps} />;
      case 'person_horizontal_alternate':
        return <LayoutAlternatePerson {...defaultProps} />;
      case 'content_horizontal_alternate':
        return <LayoutAlternateContent {...defaultProps} />;
      case 'content_horizontal_graphs':
        return <LayoutAlternateContentGraph {...defaultProps} />;
      case 'content_list_highlight':
        return <LayoutListHighlight {...defaultProps} />;
      case 'features_columns':
        return <LayoutFeaturesColumns {...defaultProps} />;
      case 'features_columns':
        return <LayoutFeaturesColumns {...defaultProps} />;
      case 'map':
        return <LayoutMap {...defaultProps} />;
      case 'content_list_map':
        return <LayoutListMap {...defaultProps} />;
      case 'content_list_gallery':
        return <LayoutListGallery {...defaultProps} />;
      case 'content_list_text_slider':
        return <LayoutListContentTextSlideshow {...defaultProps} />;
      case 'content_list_grid_slideshow':
        return <LayoutListContentGridSlideshow {...defaultProps} />;
      case 'content_accordian':
        return <LayoutContentAccordian {...defaultProps} />;
      case 'contact_form':
        return <LayoutContactForm {...defaultProps} />;
      case 'content_columns':
        return <LayoutContentColumns {...defaultProps} />;
      case 'content_centered':
        return <LayoutContentCenter {...defaultProps} />;
      case 'content_newsletter':
        return <LayoutNewsletter {...defaultProps} />;
      case 'content_iframe':
        return <LayoutIframe {...defaultProps} />;
      case 'categories_list_alternate':
        return (
          <div key={i}>
            <Categories
              currentCattegory={this.props.category}
              content={content.categories}
              contentType={content.content_type}
              language={language}
            />
            <LayoutListCategoryAlternate
              content={content}
              language={language}
              currency_symbol={this.props.currency_symbol}
            />
            <LoadButton
              status={status}
              content={content}
              loadMoreContentPage={this.props.loadMoreContentPage}
            />
          </div>
        );
      case 'column_group':
        return <LayoutColumsGroup key={i} content={content} />;
      case 'cotent_slider':
        return (
          <LayoutContentSlider key={i} first={i === 0} content={content} />
        );

      case 'content_list_horizontal_image':
        return (
          <div key={i}>
            {content.show_categories &&
              content.categories &&
              content.categories.length > 0 && (
                <Categories
                  currentCattegory={this.props.category}
                  content={content.categories}
                  contentType={content.content_type}
                  language={language}
                />
              )}
            <LayoutListContentAlternateBackground
              {...defaultProps}
              first={i === 0 && !content.show_categories}
            />
            <LoadButton
              status={status}
              content={content}
              loadMoreContentPage={this.props.loadMoreContentPage}
            />
          </div>
        );
      case 'content_list_alternate':
        return (
          <div key={i}>
            {content.show_categories &&
              content.categories &&
              content.categories.length > 0 && (
                <Categories
                  currentCattegory={this.props.category}
                  content={content.categories}
                  contentType={content.content_type}
                  language={language}
                />
              )}
            <LayoutListContentAlternate
              {...defaultProps}
              first={i === 0 && !content.show_categories}
            />
            <LoadButton
              status={status}
              content={content}
              loadMoreContentPage={this.props.loadMoreContentPage}
            />
          </div>
        );

      case 'content_list_grid':
        return (
          <div key={i}>
            {content.show_categories &&
              content.categories &&
              content.categories.length > 0 && (
                <Categories
                  first={i === 0}
                  currentCattegory={this.props.category}
                  content={content.categories}
                  contentType={content.content_type}
                  language={language}
                />
              )}
            <LayoutListContentGrid
              content={content}
              first={i === 0 && !content.show_categories}
            />
            <LoadButton
              status={status}
              content={content}
              loadMoreContentPage={this.props.loadMoreContentPage}
            />
          </div>
        );
      default:
        break;
    }
  }

  processContent() {
    const { content, status, language } = this.props;
    // Exit if no content
    if (!content.acf.content || content.acf.content.length === 0) return false;
    return content.acf.content.map((cont, i) => {
      return this.renderContent(
        cont.acf_fc_layout,
        cont.fields,
        i,
        language,
        status
      );
    });
  }

  render() {
    const { content } = this.props;
    return content && <div className={'content'}>{this.processContent()}</div>;
  }
}
