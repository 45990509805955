import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Input } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import Title from '../Title';
import './styles.scss';

export default class LayoutContentFilter extends Component {
  constructor(props) {
    super(props);
    const hirachyContent = props.content.content && this.groupItems();
    this.state = {
      hirachyContent: hirachyContent,
      selected: props.content.selected || 'all',
      selectedItem: '',
      menu: props.content.selected
        ? hirachyContent[props.content.selected]
        : props.content.content,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleActiveMenuItem = this.toggleActiveMenuItem.bind(this);
  }

  groupItems() {
    let hirachyContent = {};
    this.props.content.content.map(item => {
      hirachyContent[item.parent] = [
        ...(hirachyContent[item.parent] || []),
        item,
      ];
    });
    return hirachyContent;
  }

  toggleMenu(menuItem) {
    const selectMenu = this.state.selected === menuItem ? 'all' : menuItem;
    this.setState({
      selected: selectMenu,
      selectedItem: '',
      menu:
        selectMenu === 'all'
          ? this.props.content.content
          : this.state.hirachyContent[selectMenu],
    });
  }

  toggleActiveMenuItem(menuItem) {
    this.setState({
      selectedItem: menuItem.target.value,
    });
  }

  renderSelectOptions() {
    const renderContries = [];
    const uniqueCountries = [];

    this.state.menu.map((item, itemIndex) => {
      if (!uniqueCountries.includes(item.title)) {
        uniqueCountries.push(item.title);
        renderContries.push(
          <option value={item.title} key={itemIndex}>
            {item.title}
          </option>
        );
      }
    });

    return renderContries;
  }

  randomFullCard() {
    const min = Math.ceil(2);
    const max = Math.floor(this.state.menu.length);
    return Math.floor(Math.random() * (max - min)) + min;
  }
  renderCards() {
    let thisRow = [];
    let globalMenu = [];
    let i = 0;
    let slideFull = false;
    let slideSize = 6;

    this.state.menu.map((item, itemIndex) => {
      if (!item.image) return;
      // const random = this.randomFullCard();
      // if (
      //   this.state.menu.length > 2 &&
      //   Math.floor(this.state.menu.length / 2) == itemIndex &&
      //   !slideFull
      // ) {
      //   slideSize = 12;
      //   slideFull = true;
      // }
      //lets push content into row
      thisRow.push(
        <Col key={itemIndex} xs="12" md={slideSize}>
          <div
            className={`menu-item fadeInUp animated ${
              slideSize === 12 ? 'full' : 'half'
            } `}
            style={{
              animationDelay: `${i / 2 + 0.01}s`,
              animationDuration: `1s`,
              backgroundImage: `url('${item.image.url}')`,
            }}
          >
            <div className={`item-over ${!item.image ? 'noImage' : ''}`}>
              <div>
                <p className={'title'}>{item.title}</p>
                <p
                  className={'description'}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </div>
            </div>
          </div>
        </Col>
      );
      i++;
    });

    // now we return the global object
    return <Row>{thisRow}</Row>;
  }

  render() {
    const { content, title } = this.props.content;
    return (
      <div className={'filtermenu'}>
        <div className={'top-content'}>
          <Container>
            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              <Title>{title}</Title>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              <ul>
                {Object.keys(this.state.hirachyContent)
                  .sort()
                  .map((category, index) => (
                    <li key={index} className={'fadeInUp animated'}>
                      <a
                        className={
                          category === this.state.selected ? 'active' : ''
                        }
                        onClick={() => this.toggleMenu(category)}
                      >
                        {category}
                      </a>
                    </li>
                  ))}
              </ul>
              <FormGroup>
                <Input
                  onChange={e => this.toggleActiveMenuItem(e)}
                  value={this.state.selectedItem}
                  type="select"
                  name="select"
                  className="selectHelper"
                >
                  <option value=""> </option>
                  {this.renderSelectOptions()}
                </Input>
              </FormGroup>
            </ScrollAnimation>
          </Container>
        </div>
        <Container className={'container-uptop'}>
          {this.renderCards()}
        </Container>
      </div>
    );
  }
}
