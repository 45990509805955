import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.scss';
import ReactPlayer from 'react-player';

function TexBlockBackground(props) {
  function renderVisual() {
    if (props.content.video && props.content.video !== '') {
      return renderVideo();
    } else {
      return renderImage();
    }
  }
  function renderVideo() {
    return (
      <Col
        xs="12"
        md="6"
        className={'align-items-center align-self-center imageContent'}
      >
        <ReactPlayer url={props.content.video} width={'100%'} />
      </Col>
    );
  }
  function renderImage() {
    return (
      <Col
        xs="12"
        md="6"
        className={`align-items-center align-self-center clearfix imageContent ${
          props.content.image.url || props.content.image.src ? '' : 'noimage'
        }`}
        style={{
          backgroundImage: `url(${props.content.image.url ||
            props.content.image.src})`,
        }}
      ></Col>
    );
  }

  function renderText() {
    return (
      <div className={'content-wrap'}>
        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
          {props.content.sub_title && (
            <h6
              dangerouslySetInnerHTML={{
                __html: props.content.sub_title,
              }}
            />
          )}
          <h5 dangerouslySetInnerHTML={{ __html: props.content.title }} />

          <div dangerouslySetInnerHTML={{ __html: props.content.content }} />
        </ScrollAnimation>
      </div>
    );
  }
  return (
    <div
      id={props.id}
      className={`horizontal-text-block-background ${
        props.odd === 1 ? 'odd' : 'even'
      } ${props.first ? 'first' : ''} ${
        props.content.with_background ? 'color' : 'white'
      }`}
    >
      {props.content.link ? (
        <Link to={props.content.link}>
          <Container>
            <Row>
              {!props.odd && renderVisual()}
              <Col
                xs="12"
                md="6"
                className={'content align-items-center align-self-center'}
              >
                {renderText()}
              </Col>
              {props.odd === 1 && renderVisual()}
            </Row>
          </Container>
        </Link>
      ) : (
        <Container>
          <Row>
            {!props.odd && renderVisual()}
            <Col
              xs="12"
              md="6"
              className={'content align-items-center align-self-center'}
            >
              {renderText()}
            </Col>
            {props.odd === 1 && renderVisual()}
          </Row>
        </Container>
      )}
    </div>
  );
}

export default TexBlockBackground;
